(function ($) {
  Drupal.behaviors.gnavUtilityItemV1 = {
    attach: function (context) {
      var $html = $('html');
      var $modules = $('.js-gnav-utility-item--v1', context);

      function _closeOverlay($utilityItem) {
        var $itemTrigger = $('.js-gnav-utility-item-trigger', $utilityItem);
        $utilityItem.removeClass('gnav-utility-item--active');
        $html.removeClass('active-utility-overlay');
        $itemTrigger.attr('aria-expanded', 'false');
      }

      $modules.each(function () {
        var $module = $(this);
        var $overlayTrigger = $('.js-gnav-utility-item-trigger', $module);
        var $contentOverlay = $('.js-gnav-utility-item-overlay', $module);
        var $closeOverlayTrigger = $('.js-gnav-utility-item-overlay-close', $contentOverlay);
        var hoverTriggerEvents = $overlayTrigger.data('trigger-on-hover');

        $overlayTrigger.off('mouseover.overlay').on(
          'mouseover.overlay',
          _.debounce(function () {
            if (Drupal && Drupal.settings && Drupal.settings.disable_cart_overlay) {
              return;
            }
            if ($contentOverlay.length > 0 && hoverTriggerEvents) {
              $html.addClass('active-utility-overlay');
              $(this).attr('aria-expanded', 'true');
              $contentOverlay.trigger('launch.overlay');
            }
          }, 100)
        );
        $contentOverlay.off('launch.overlay').on('launch.overlay', function () {
          var $btnTrigger = $(this).siblings('.js-gnav-utility-item-trigger');
          var $utilItemsParent = $btnTrigger.closest('.js-site-header-formatter-utilities');
          var $utilityItems = $('.js-site-header-formatter-utilities-item', $utilItemsParent);

          if ($btnTrigger.length > 0 && hoverTriggerEvents) {
            $utilityItems.removeClass('gnav-utility-item--active');
            $btnTrigger
              .closest('.js-site-header-formatter-utilities-item')
              .addClass('gnav-utility-item--active');
          }
        });
        $module.off('mouseleave.overlay').on(
          'mouseleave.overlay',
          _.debounce(function (e) {
            var $utilityItem = $(this).closest('.js-site-header-formatter-utilities-item');

            if (!hoverTriggerEvents) {
              return;
            }
            // Avoid mouseout on select input suggestion & on hover input field(Firefox).
            if (e.relatedTarget === null || e.relatedTarget.nodeName.toLowerCase() === 'input') {
              return;
            }
            _closeOverlay($utilityItem);
          }, 100)
        );

        $closeOverlayTrigger.once().on('click', function () {
          var $utilityItem = $(this).closest('.js-site-header-formatter-utilities-item');

          _closeOverlay($utilityItem);
        });
      });
    }
  };
})(jQuery);
